import React, { useState, useRef, useEffect } from "react";
import "./Layout.css";
import { story } from "../content/story";
import { containsPhrase } from "../helper/helper";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

const Layout = () => {
  const [transcript, setTranscript] = useState("");
  const [currentPart, setCurrentPart] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const videoRef = useRef(null);
  const currentPartRef = useRef(currentPart);

  useEffect(() => {
    const videoElement = document.getElementById('videoElement');
    if (videoElement) {
      videoRef.current = videoElement;
      // Add any additional logic for video interaction here
    }
  }, []);
  useEffect(() => {
    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptText = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          setTranscript((prev) => prev + transcriptText + " ");
          checkPartMatch(transcriptText);
        } else {
          interimTranscript += transcriptText;
        }
      }
    };

    recognition.onerror = (event) => {
      console.error(event.error);
    };
  }, [currentPart]);

  const checkPartMatch = (text) => {
    console.log(" story[currentPart]", story[currentPart]);

    console.log(
      "containsPhrase(text, story[currentPart].leniency)",
      containsPhrase(text, story[currentPart].leniency)
    );
    if (containsPhrase(text, story[currentPart].leniency)) {
      playVideo();
    }
  };

  const nextButtonPressed = () => {
    console.log("next button pressed");
    setCurrentPart((prev) => {
      const newPart = prev + 1;
      if (newPart < story.length) {
        return newPart;
      } else {
        if (newPart === story.length) {
          setEndReached(true);
          return newPart;
        }
        return prev;
      }
    });
  };

  const prevButtonPressed = () => {
    setCurrentPart((prev) => {
      const newPart = prev - 1;
      if (newPart >= 0) {
        setEndReached(false);
        return newPart;
      } else {
        console.log("the newpart does exist", newPart);
        return prev;
      }
    });
  };

  const playVideo = () => {
    const video = videoRef.current;
    console.log("video", video)
    let isPlaying = false;

    if (video && story[currentPart]) {
      // On video playing toggle values
      video.onplaying = function () {
        isPlaying = true;
      };

      // On video pause toggle values
      video.onpause = function () {
        isPlaying = false;
      };

      // Pause video function
      const pauseVid = () => {
        if (!video.paused && isPlaying) {
          video.pause();
        }
      };

      // Play video function
      const playVid = async () => {
        if (video.paused && !isPlaying) {
          return video.play();
        }
      };

      // Ensure video is paused before starting new playback
      pauseVid();

      currentPartRef.current = currentPart;
      console.log(" story[currentPart]", story[currentPart]);
      const { start, end } = story[currentPart].part;

      const stopPlayback = () => {
        if (video.currentTime >= end) {
          video.pause();
          video.currentTime = end;
          video.removeEventListener("timeupdate", stopPlayback);
        }
      };

      video.currentTime = start;
      video.addEventListener("timeupdate", stopPlayback);
      playVid();

      return () => {
        video.removeEventListener("timeupdate", stopPlayback);
      };
    }
  };

  const toggleListening = () => {
    console.log("Pressed")
    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
    setIsListening(!isListening);
  };

  return (
    <div className="container">
      <nav className="navbar">
        <img src="/Logo.svg" alt="Blive Logo" className="logoMain" />
      </nav>
      <div className="main-content">
        <div className="video-section">
          {/* <video
            ref={videoRef}
            src="/MainStory.mp4"
            className="video-placeholder"
            playsInline
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video> */}
          {/* <div
              dangerouslySetInnerHTML={{
                __html: `
                <video
                  ref="${videoRef}"
                  muted={true}
                  playsInline={true}
                  className="video-placeholder"
                  src="/MainStory.mp4"
                  >
                <source muted={true} src="/MainStory.mp4" type="video/mp4" />
                </video>`,
              }}
            /> */}
             <div
            dangerouslySetInnerHTML={{
              __html: `
                <video
                  id="videoElement"
                  ref="videoRef"
                  muted
                  playsinline
                  class="video-placeholder"
                  src="/MainStory.mp4"
                  type="video/mp4"
                >
                  Your browser does not support the video tag.
                </video>
              `,
            }}
          />
        </div>
        <div className="text-section">
          <button
            className={`toggle-listening ${
              isListening ? "listening" : ""
            } bigger`}
            onClick={toggleListening}
          >
            <div className="icon">
              {isListening ? (
                <img src="/mute.svg" alt="Mute" className="mute" />
              ) : (
                <img src="/mic.svg" alt="Mic" className="mic" />
              )}
            </div>
            {isListening ? "Stop" : "Speak"}
          </button>
          <div className="text-container">
            {endReached ? (
              <p>
                {" "}
                This is the end of the short story! Please feel free to provide
                your review{" "}
                <a href="https://forms.gle/diNoZqN64r55uLp49">here!</a>
              </p>
            ) : (
              <p>{story[currentPart].text}</p>
            )}
          </div>
          <button
            className={`toggle-listening ${
              isListening ? "listening" : ""
            } smaller`}
            onClick={toggleListening}
          >
            <div className="icon">
              {isListening ? (
                <img src="/mute.svg" alt="Mute" className="mute" />
              ) : (
                <img src="/mic.svg" alt="Mic" className="mic" />
              )}
            </div>
            {isListening ? "Stop" : "Speak"}
          </button>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
            Phasellus ac dui neque.
          </p>
          <p>Phasellus ac dui neque. Etiam eleifend,</p>
          <p>Etiam eleifend, diam sit amet imperdiet ornare</p> */}
          {/* <p>{transcript}</p> */}
        </div>
      </div>
      <div className="pagination">
        <button className="pagination-button" onClick={prevButtonPressed}>
          {"<"}
        </button>
        <button className="pagination-button" onClick={nextButtonPressed}>
          {">"}
        </button>
      </div>
    </div>
  );
};

export default Layout;
