import React, { useState } from "react";
import "./Popup.css";
import axios from "axios";

const Popup1 = ({ onNext }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://doolbetaserver.onrender.com/blive/code",
        { code },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
     console.log("response",response)
      if (code && response.data.success === true) {
        onNext();
      }
      else{
        setError("Wrong Code Try Again");
    }
    } catch (error) {
      console.error("handleSubmit blive code", error);
      setError("Wrong Code Try Again");
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
          <h1>
            Welcome to{" "}
            <img src={"/Logo.svg"} alt="Blive Logo" className="logo" />
          </h1>
          <label htmlFor="code">Your Code:</label>
          <input
            type="text"
            id="code"
            value={code}
            onChange={(e) => {setCode(e.target.value);
            setError("")}}
            required
            className="code-input"
          />
          <p className="error">{error}</p>
          <button type="submit" className="submit-button">
           Submit and Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default Popup1;
