import React, { useState } from 'react';
import './Popup.css';
import axios from "axios";

const Popup2 = ({ onClose }) => {
  const [name, setName] = useState('');
  const [agreed, setAgreed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://doolbetaserver.onrender.com/blive/confirmation",
        { agreed, name },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
     console.log("response",response)
      if (name && agreed && response.data.success === true) {
        onClose();
      }

    } catch (error) {
      console.error("handleSubmit blive code", error);
    }
  };


  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
          <h2>Your Name</h2>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={agreed}
              onChange={() => setAgreed(!agreed)}
              required
            />
            <span>
              By clicking this button, I agree to the terms and conditions of the
              <a href="https://docs.google.com/document/d/1lVCF-NMU-mIutr0uusKZrlcxwkVNg63wCA_bfPnXf9M/edit?usp=sharing"> Non disclosure statement</a>
            </span>
          </div>
          <button type="submit" className="submit-button">Submit and Continue</button>
        </form>
      </div>
    </div>
  );
};

export default Popup2;
