// export const story = 
//     {
//       1: [
//         {
//           text: "In a night sky filled with twinkling stars and a glowing moon",
//           leniency: ["twinkling stars", "glowing moon"],
//           part: { start: 0, end: 10 },
//         },
//         {
//           text: "Stella the Star and Luna the Moon were the best of friends.",
//           leniency: ["best of friends", "Stella the Star", "Luna the Moon"],
//           part: { start: 10, end: 20 },
//         },
//         {
//           text: "Every night, they lit up the sky, making it beautiful for everyone to see.",
//           leniency: ["they lit up the sky", "beautiful for everyone to see"],
//           part: { start: 20, end: 30 },
//         },
//       ],
//       2: [
//         {
//           text: "One peaceful night, as Stella and Luna were shining brightly, something unexpected happened.",
//           leniency: [
//             "Stella and Luna were shining brightly",
//             "something unexpected happened",
//           ],
//           part: { start: 40, end: 50 },
//         },
//         {
//           text: "Suddenly, Nimbus the Cloud drifted in and covered Luna the Moon, plunging the night sky into darkness.",
//           leniency: ["drifted in and covered Luna", "plunging the night sky"],
//           part: { start: 50, end: 60 },
//         },
//         {
//           text: "The stars began to murmur in confusion.",
//           leniency: ["began to murmur", "murmur in confusion"],
//           part: { start: 60, end: 70 },
//         },
//       ],
//     };
  
    export const story = [
      {
        text: "A crow was sitting on a tree doing nothing at all",
        leniency: ["was sitting", "on a tree", "doing nothing"],
        part: { start: 0, end: 3 },
      },
      {
        text: "A small rabbit saw a crow and asked him",
        leniency: ["small rabbit", "saw a crow", "asked him"],
        part: { start: 3, end: 8 },
      },
      {
        text: "'Can I also sit like you and do nothing all day long?'",
        leniency: ["also sit like", "do nothing", "all day long"],
        part: { start: 8, end: 13 },
      },
      {
        text: "'Sure why not' answered the crow",
        leniency: [
          "Sure why not",
          "answered",
          "the crow"
        ],
        part: { start: 13, end: 15 },
      },
      {
        text: "Then the rabbit sat below the ground and rested",
        leniency: ["Then the rabbit", "sat below", "the ground", "and rested"],
        part: { start: 15, end:  22},
      },
      {
        text: "And slept without any care",
        leniency: ["and", "slept", "care"],
        part: { start: 22, end: 30 },
      },
      {
        text: "But suddenly a fox appeared",
        leniency: ["suddenly", "a fox", "appeared"],
        part: { start: 30, end: 34 },
      },
    ]
