export const normalizeText = (text) => {
    return text
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"")
      .replace(/\s{2,}/g," ");
  };
  
  export const containsPhrase = (text, phrases) => {
    const normalizedText = normalizeText(text);
    return phrases.some(phrase => normalizedText.includes(normalizeText(phrase)));
  };