import React, { useState } from "react";
import Layout from "./components/Layout";
import Popup from "./components/Popup";
import Popup1 from "./components/Popup1";
import Popup2 from "./components/Popup2";

function App() {
  const [popupStep, setPopupStep] = useState(1);

  const handleNextStep = () => {
    setPopupStep(popupStep + 1);
  };

  const handleClose = () => {
    setPopupStep(0);
  };

  return (
    <div className="App">
      {popupStep === 1 && <Popup1 onNext={handleNextStep} />}
      {popupStep === 2 && <Popup2 onClose={handleClose} />}
      <Layout />
    </div>
  );
}

export default App;
